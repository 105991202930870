<template>
  <div class="wrap">
    <PullRefresh
      success-text="刷新成功"
      v-model="refreshing"
      @refresh="onRefresh"
      class="refresh"
    >
      <List
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        v-if="list.length"
      >
        <div v-for="(item, index) in list" :key="index" class="orderWrap">
          <div class="titleWrap">
            <span>{{item}}区</span>
          </div>
          <div class="conWrap">
            <span class="text1">区域编号</span>
            <span class="text1">A</span>
          </div>
          <div class="conWrap">
            <span class="text1">区域状态</span>
            <span class="text1">在线</span>
          </div>
          <div class="conWrap2" @click="goDetail(item)">管理柜门</div>
        </div>
      </List>
      <Empty description="暂时没有订单" v-else />
    </PullRefresh>
  </div>
</template>

<script>
import { Empty, PullRefresh, List  } from 'vant'
export default {
  name: 'boxDetail',
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      list: [1, 2, 3, 4, 5]
    }
  },
  components: {
    Empty, PullRefresh, List
  },
  methods: {
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }

        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        this.loading = false;

        if (this.list.length >= 20) {
          this.finished = true;
        }
      }, 1000);
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    goDetail(data){
      this.$router.push(`/doorDetail/${data}`)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .wrap{
    flex-grow: 1;
    overflow: auto;
    /* background: #fff; */
    font-size: 14px;
  }
  .refresh{
    height: auto;
  }
  .listWrap{
    height: 100%;
    overflow: auto;
  }
  .box{
    min-height: calc(100vh - 0.8rem);
  }
  .orderWrap{
    background: linear-gradient(135deg,#1ae179 0%,#108c97 100%);
    border-radius: 10px;
    margin-bottom: 15px;
  }
  .titleWrap{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bolder;
    border-bottom: 1px solid #f2f3f5;
    color: #fff;
  }
  .conWrap{
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
  }
  .conWrap2{
    padding: 15px 20px;
    text-align: center;
    color: #fff;
    border-top: 1px solid #f2f3f5;
  }
  .text1{
    color: #fff;
  }
  .status{
    color: #1c19fa;
  }
</style>
