<template>
  <div class="wrap">
    <PullRefresh
      success-text="刷新成功"
      v-model="loading"
      @refresh="onRefresh"
      class="refresh"
    >
      <div class="box">
        <div class="orderWrap">
          <div class="titleWrap">
            <span>{{ name || '--' }}</span>
          </div>
          <Field
            v-model="lockerName"
            required
            label="设备名称："
            placeholder="请输入设备名称"
          />
          <Field
            v-model="lockerId"
            required
            label="设备编号："
            placeholder="请输入设备编号"
            readonly
          />
          <div class="outWrap">
            <div class="radioWrap">
              <span><span style="color: red">*</span>设备微信类型：</span>
              <RadioGroup v-model="lockerServerType" direction="horizontal">
                <Radio name="mp">公众号</Radio>
                <Radio name="miniapp">小程序</Radio>
              </RadioGroup>
            </div>
          </div>
          <Field
            v-model="lockerTotal"
            type="digit"
            label="总柜门数："
            placeholder="柜门数不能大于100"
          />
          <Field
            v-model="lockerLockNum"
            required
            type="digit"
            label="锁定柜门号："
            placeholder="请输入锁定柜门号"
          />
          <Field
            v-model="depositAmount"
            type="number"
            label="押金："
            placeholder="请输入押金"
          />
          <Field
            v-model="lockerAmount"
            type="number"
            label="收费："
            placeholder="请输入收费金额"
          />
        </div>
        <Button type="primary" size="large" @click="save" style="margin-bottom: 20px;">提交</Button>
        <Button type="primary" size="large" :disabled="lockerQrImage" @click="setEwm">{{ lockerQrImage ? '已设置二维码' : '设置二维码' }}</Button>
      </div>
    </PullRefresh>
    <Overlay :show="show" class="overlay">
      <Loading type="spinner" />
    </Overlay>
  </div>
</template>

<script>
import { PullRefresh, Overlay, Loading, Field, Button, RadioGroup, Radio } from 'vant'
import axios from 'axios'

export default {
  name: 'boxManage',
  data() {
    return {
      name: '',
      show: false,
      refreshing: false,
      loading: false,
      finished: true,
      lockerName: '',
      lockerId: '',
      lockerAmount: '27',
      depositAmount: '10',
      lockerTotal: '64',
      lockerLockNum: '',
      lockerQrImage: false,
      lockerServerType: ''
    }
  },
  created() {
    const name = window.decodeURIComponent(window.location.href.split('=')[1])
    this.name = name
    this.getData()
  },
  components: {
    PullRefresh, Overlay, Loading, Field, Button, RadioGroup, Radio
  },
  methods: {
    getData(){
      this.show = true
      axios.post('/sso/static/selectLockerOne', {
        deviceId: this.$route.params.id
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          const data = res.data.result || {}
          this.lockerName = data.lockerName
          this.lockerId = data.lockerId
          this.lockerAmount = data.lockerAmount
          this.depositAmount = data.depositAmount
          this.lockerTotal = data.lockerTotal
          this.lockerLockNum = data.lockerLockNum
          this.lockerServerType = data.lockerServerType
          this.lockerQrImage = !!data.lockerQrImage
          this.show = false
          this.loading = false
        } else {
          this.show = false
          this.loading = false
        }
      })
    },
    onRefresh(){
      this.getData()
    },
    save(){
      if (!this.lockerName || !this.lockerId || !this.lockerLockNum || !this.lockerServerType) {
        this.$toast.fail('设备名称、设备编号、设备微信类型、锁定柜门号必须填写！')
        return
      }
      this.show = true
      axios.post('/sso/static/setUpdateDeviceByAdmin', {
        deviceId: this.$route.params.id,
        lockerName: this.lockerName,
        lockerAmount: this.lockerAmount,
        depositAmount: this.depositAmount,
        lockerTotal: this.lockerTotal,
        lockerLockNum: this.lockerLockNum,
        lockerServerType: this.lockerServerType
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          const status = res.data.result
          if (status === 1) {
            this.$toast.success('修改成功')
          } else{
            this.$toast.fail('修改失败')
          }
          this.getData()
          this.show = false
          this.loading = false
        } else {
          this.show = false
          this.loading = false
        }
      })
    },
    setEwm(){
      if (!this.lockerServerType) {
        this.$toast.fail('请先选择设备微信类型！')
        return
      }
      this.show = true
      axios.post('/sso/static/setLockerQrParam', {
        deviceId: this.$route.params.id,
        lockerServerType: this.lockerServerType
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          const status = res.data.result
          if (status === 1) {
            this.$toast.success('二维码设置成功')
          } else{
            this.$toast.fail('二维码设置失败')
          }
          this.getData()
          this.show = false
          this.loading = false
        } else {
          this.show = false
          this.loading = false
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .wrap{
    flex-grow: 1;
    overflow: hidden;
    /* background: #fff; */
    font-size: 14px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .refresh{
    height: auto;
  }
  .listWrap{
    height: 100%;
    overflow: auto;
  }
  .box{
    min-height: calc(100vh - 0.8rem);
  }
  .inputWrap{
    position: absolute;
    height: 35px;
  }
  .orderWrap{
    background: linear-gradient(135deg,#1ae179 0%,#108c97 100%);
    border-radius: 10px;
    margin-bottom: 15px;
    overflow: hidden;
  }
  .titleWrap{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bolder;
    border-bottom: 1px solid #f2f3f5;
    color: #fff;
    position: relative;
  }
  .conWrap{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #ccc;
  }
  .conWrap:last-child{
    border-bottom: 0;
  }
  .text1{
    color: #fff;
    display: inline-block;
    line-height: 32px;
  }
  .status{
    color: #1c19fa;
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    color: transparent;
  }
  .btn{
    background-color: #0744c1;
    border-radius: 5px;
    border: 0;
  }
  .topBtn{
    background-color: #0744c1;
    border-radius: 5px;
    border: 0;
    position: absolute;
    right: 20px;
    top: 10px;
  }
  .overlay{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .outWrap{
    padding: 0 10px;
    background: #fff;
  }
  .radioWrap{
    display: flex;
    font-size: 14px;
    height: 44px;
    align-items: center;
    border-bottom: 0.02667px solid #ebedf0;
  }
</style>
