import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './routers'
import { Toast, Notify } from 'vant'
import store from './store'
import './config/axiosconfig.js'
import "amfe-flexible"
import "normalize.css"
import wx from "weixin-js-sdk"
import VueWechatTitle from 'vue-wechat-title';
import "./assets/iconfont/iconfont.css"

Vue.use(VueWechatTitle);
Vue.use(VueRouter)

// 挂在vue的原型上方便使用
Vue.prototype.$wx = wx;
Vue.prototype.$toast = Toast;
Vue.prototype.$notify = Notify;
Vue.config.productionTip = false


new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: { App }
})