<template>
  <div class="wrap">
    <div class="wrap2">
      <PullRefresh
        success-text="刷新成功"
        v-model="loading"
        @refresh="onRefresh"
        class="refresh"
      >
        <div v-if="list.length" class="box">
          <div v-for="(item, index) in list" :key="index" class="orderWrap">
            <div class="conWrap">
              <span class="text0">{{ item.areaName }}</span>
            </div>
            <div class="con2">
              <div>
                <div class="text1">昨日单量</div>
                <div class="text2">{{ item.yesterdayCount || 0 }}</div>
              </div>
              <div>
                <div class="text1">本月单量</div>
                <div class="text2">{{ item.nowMonthCount || 0 }}</div>
              </div>
              <div>
                <div class="text1">上月单量</div>
                <div class="text2">{{ item.lastMonthCount || 0 }}</div>
              </div>
            </div>
          </div>
        </div>
        <Empty description="暂时没有订单" v-else />
      </PullRefresh>
    </div>
    <Overlay :show="show" class="overlay">
      <Loading type="spinner" />
    </Overlay>
    <div class="fixedBtn" @click="$router.push('/orderList')">查订单</div>
  </div>
</template>

<script>
import { Empty, PullRefresh, Overlay, Loading } from 'vant'
import axios from 'axios'
export default {
  name: 'orderManage',
  data() {
    return {
      show: false,
      loading: false,
      list: []
    }
  },
  created() {
    this.getData()
  },
  components: {
    Empty, PullRefresh, Overlay, Loading
  },
  methods: {
    getData(){
      this.show = true
      axios.post('/sso/order/orderReportCount', {}).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          this.list = res.data.result || []
          this.show = false
          this.loading = false
        } else {
          this.show = false
          this.loading = false
        }
      })
    },
    onRefresh(){
      this.getData()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .wrap{
    flex-grow: 1;
    overflow: hidden;
    /* background: #fff; */
    font-size: 14px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .wrap2{
    overflow: auto;
    flex-grow: 1;
    margin-top: 15px;
  }
  .refresh{
    height: auto;
  }
  .listWrap{
    height: 100%;
    overflow: auto;
  }
  .box{
    min-height: calc(100vh - 0rem);
  }
  .orderWrap{
    background: linear-gradient(135deg,#1ae179 0%,#108c97 100%);
    border-radius: 10px;
    margin-bottom: 15px;
    color: #fff;
  }
  .titleWrap{
    position: relative;
    padding-left: 75px;
  }
  .conWrap{
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #fff;
  }
  .con2{
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
    font-size: 18px;
  }
  .con2>div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .text0{
    color: #fff;
    font-size: 20px;
    font-weight: bold;
  }
  .text1{
    color: #fff;
    font-size: 14px;
    margin-bottom: 15px;
  }
  .imgWrap{
    width: 65px;
    height: 65px;
    border-radius: 35px;
    position: absolute;
    left: 15px;
    top: 7px;
  }
  .fixedBtn{
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 150px;
    right: 0px;
    background: rgb(224, 124, 9);
    color: #fff;
    border-radius: 50px;
    font-size: 13px;
    text-align: center;
    line-height: 50px;
    box-shadow: 0px 0 0 5px rgba(224, 124, 9, 0.5);
  }
  .overlay{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    color: transparent;
  }
</style>
