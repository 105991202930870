<template>
  <div class="wrap">
    <PullRefresh
      success-text="刷新成功"
      v-model="loading"
      @refresh="onRefresh"
      class="refresh"
    >
      <div v-if="list.length" class="box">
        <div v-for="(item, index) in list" :key="index" class="orderWrap">
          <div class="titleWrap">
            <span>{{ item.organizationName }}</span>
          </div>
          <div class="conWrap">
            <span class="text1">姓名</span>
            <span class="text2">{{ item.nickName }}</span>
          </div>
          <div class="conWrap">
            <span class="text1">登陆账号</span>
            <span class="text2">{{ item.userName }}</span>
          </div>
          <div class="conWrap">
            <span class="text1">联系方式</span>
            <span class="text2">{{ item.phoneNumber }}</span>
          </div>
          <div class="conWrap">
            <span class="text1">创建时间</span>
            <span class="text2">{{ item.createTime }}</span>
          </div>
        </div>
      </div>
      <Empty description="暂无相关信息" v-else />
    </PullRefresh>
    <Overlay :show="show" class="overlay">
      <Loading type="spinner" />
    </Overlay>
  </div>
</template>

<script>
import { Empty, PullRefresh, Overlay, Loading } from 'vant'
import axios from 'axios'
export default {
  name: 'userManage',
  data() {
    return {
      show: false,
      loading: false,
      list: []
    }
  },
  created() {
    this.getData()
  },
  components: {
    Empty, PullRefresh, Overlay, Loading
  },
  methods: {
    getData(){
      this.show = true
      axios.post('/sso/customer/pageList', { pageNo: 1, pageSize: 500 }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          this.list = res.data.result.data || []
          this.show = false
          this.loading = false
        } else {
          this.show = false
          this.loading = false
        }
      })
    },
    onRefresh(){
      this.getData()
    },
    goDetail(data){
      this.$router.push(`/orderDetail/${data.orderTradeNo}`)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .wrap{
    flex-grow: 1;
    overflow: auto;
    /* background: #fff; */
    font-size: 14px;
  }
  .refresh{
    height: auto;
  }
  .listWrap{
    height: 100%;
    overflow: auto;
  }
  .box{
    min-height: calc(100vh - 0.8rem);
  }
  .orderWrap{
    background: #fff;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  .titleWrap{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bolder;
    border-bottom: 1px solid #f2f3f5;
  }
  .conWrap{
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
  }
  .text1{
    color: #999393;
  }
  .overlay{
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
