<template>
  <div class="wrap">
    <div>
      <Field
        v-model="time1"
        center
        readonly
        @focus="show2 = true"
        label="开始日期"
        placeholder="请选择开始日期"
        class="inputWrap"
      />
      <Field
        v-model="time2"
        center
        readonly
        @focus="show3 = true"
        label="结束日期"
        placeholder="请选择结束日期"
        class="inputWrap"
      >
        <template #button>
          <Button size="small" type="primary" @click="onRefresh">搜索</Button>
        </template>
      </Field>
    </div>
    <div class="wrap2">
      <PullRefresh
        success-text="刷新成功"
        v-model="loading"
        @refresh="onRefresh"
        class="refresh"
      >
        <div v-if="list.length" class="box">
          <div v-for="(item, index) in list" :key="index" class="orderWrap">
            <div class="conWrap">
              <span class="text1">{{ item.areaName }}</span>
            </div>
            <div v-if="item.lockerList.length">
              <div v-for="(ele, j) in item.lockerList" :key="j" class="itemWrap">
                <div class="qyWrap">{{ ele.lockerName }}</div>
                <div>
                  <div>订单总数</div>
                  <div class="num">{{ ele.orderCount }}</div>
                </div>
                <div>
                  <div>退款数</div>
                  <div class="num">{{ ele.refundCount }}</div>
                </div>
                <div>
                  <div>订退款率</div>
                  <div class="num">{{ ele.refundRateStr }}</div>
                </div>
              </div>
            </div>
            <div v-else class="emptyWrap">暂无数据</div>
          </div>
        </div>
        <Empty description="暂无相关信息" v-else />
      </PullRefresh>
    </div>
    <Overlay :show="show" class="overlay">
      <Loading type="spinner" />
    </Overlay>
    <Popup v-model="show2" position="bottom">
      <DatetimePicker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        @confirm="confirm"
        @cancel="show2=false"
      />
    </Popup>
    <Popup v-model="show3" position="bottom">
      <DatetimePicker
        v-model="currentDate2"
        type="date"
        title="选择年月日"
        @confirm="confirm2"
        @cancel="show3=false"
      />
    </Popup>
  </div>
</template>

<script>
import { Empty, PullRefresh, Overlay, Loading, Field, Popup, DatetimePicker, Button } from 'vant'
import axios from 'axios'
import moment from 'moment'
export default {
  name: 'orderManage',
  data() {
    return {
      time1: moment().format('YYYY-MM-DD'),
      time2: moment().format('YYYY-MM-DD'),
      currentDate: new Date(moment().format('YYYY-MM-DD')),
      currentDate2: new Date(moment().format('YYYY-MM-DD')),
      show: false,
      show2: false,
      show3: false,
      loading: false,
      list: []
    }
  },
  created() {
    this.getData()
  },
  components: {
    Empty, PullRefresh, Overlay, Loading, Field, Popup, DatetimePicker, Button
  },
  methods: {
    getData(){
      if (!this.time1 || !this.time2) {
        this.$toast("请填写查询条件后再试！")
        this.loading = false
        return
      }
      this.show = true
      axios.post('/sso/statistic/getDataReport', {
        startTime: this.time1,
        endTime: this.time2
      }).then(res => {
        if (res && res.data && res.data.statusCode === '201') {
          this.list = res.data.result || []
          this.show = false
          this.loading = false
        } else {
          this.show = false
          this.loading = false
        }
      })
    },
    onRefresh(){
      this.getData()
    },
    confirm(data){
      this.time1 = moment(data).format('YYYY-MM-DD')
      this.show2 = false
      // this.getData()
    },
    confirm2(data){
      this.time2 = moment(data).format('YYYY-MM-DD')
      this.show3 = false
      // this.getData()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .wrap{
    flex-grow: 1;
    overflow: hidden;
    /* background: #fff; */
    font-size: 14px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .wrap2{
    overflow: auto;
    flex-grow: 1;
    margin-top: 15px;
  }
  .refresh{
    height: auto;
  }
  .listWrap{
    height: 100%;
    overflow: auto;
  }
  .box{
    min-height: calc(100vh - 4.8rem);
  }
  .orderWrap{
    background: linear-gradient(135deg,#1ae179 0%,#108c97 100%);
    border-radius: 10px;
    margin-bottom: 15px;
    color: #fff;
  }
  .titleWrap{
    position: relative;
    padding-left: 75px;
  }
  .conWrap{
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
  }
  .text1{
    color: #fff;
    font-size: 20px;
    font-weight: bold;
  }
  .num{
    color: #0a4fe9;
    font-size: 20px;
    font-weight: bold;
    margin-top: 15px;
  }
  .itemWrap{
    height: 50px;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    /* margin: 10px 0; */
    padding: 10px;
    border-top: 1px dashed #fff;
  }
  .itemWrap>div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .qyWrap{
    font-size: 18px;
    font-weight: bolder;
    color: #fff;
  }
  .emptyWrap{
    text-align: center;
    padding: 20px;
    font-size: 15px;
  }
  .overlay{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    color: transparent;
  }
</style>
