import Vue from 'vue'
import Vuex from 'vuex'
 
Vue.use(Vuex)
 
const store = new Vuex.Store({
  state:{
    systemType: 'manager',// user
    token: '',
    menuList: sessionStorage.getItem('menuList') ? JSON.parse(sessionStorage.getItem('menuList')) : [],
    userVo: sessionStorage.getItem('userVo') ? JSON.parse(sessionStorage.getItem('userVo')) : {},
    counter:1000
  },
  mutations:{
    setToken(state, payload){
      state.token = payload
    },
    setUserInfo(state, payload){
      state.token = payload.token
      state.menuList = payload.menuList
      state.userVo = payload.userVO
    }
  },
  actions:{
    getUserInfo(context, data){
      context.commit('setUserInfo', data)
    },
    getUserInfo2(context, data){
      context.commit('setUserInfo', { userVO: data })
    }
  },
  getters:{
 
  },
  modules:{
    
  }
})

export default store
