import Router from 'vue-router'
import login from '@/pages/login/index.vue'
import userPage from '@/pages/userPage/index.vue'
import boxManage from '@/pages/boxManage/index.vue'
import orderManage from '@/pages/orderManage/index.vue'
import orderList from '@/pages/orderList/index.vue'
import dataManage from '@/pages/dataManage/index.vue'
import userManage from '@/pages/userManage/index.vue'
import placeSetting from '@/pages/placeSetting/index.vue'
import placeDetail from '@/pages/placeDetail/index.vue'
import boxDetail from '@/pages/boxDetail/index.vue'
import doorDetail from '@/pages/doorDetail/index.vue'
import returnOrderList from '@/pages/returnOrderList/index.vue'
import returnOrderList2 from '@/pages/returnOrderList2/index.vue'

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      meta: {
          title: '登陆'
      },
      component: login
    },
    {
      path: '/userPage',
      name: 'userPage',
      meta: {
          title: '个人中心'
      },
      component: userPage
    },
    {
      path: '/boxManage',
      name: 'boxManage',
      meta: {
          title: '机柜管理',
          keepAlive: true
      },
      component: boxManage
    },
    {
      path: '/orderManage',
      name: 'orderManage',
      meta: {
          title: '订单统计'
      },
      component: orderManage
    },
    {
      path: '/orderList',
      name: 'orderList',
      meta: {
          title: '订单管理'
      },
      component: orderList
    },
    {
      path: '/returnOrderList',
      name: 'returnOrderList',
      meta: {
          title: '本月退款'
      },
      component: returnOrderList
    },
    {
      path: '/returnOrderList2',
      name: 'returnOrderList2',
      meta: {
          title: '历史退款'
      },
      component: returnOrderList2
    },
    {
      path: '/dataManage',
      name: 'dataManage',
      meta: {
          title: '数据报表'
      },
      component: dataManage
    },
    {
      path: '/userManage',
      name: 'userManage',
      meta: {
          title: '账号管理'
      },
      component: userManage
    },
    {
      path: '/placeSetting',
      name: 'placeSetting',
      meta: {
          title: '场地录入'
      },
      component: placeSetting
    },
    {
      path: '/placeDetail/:id',
      name: 'placeDetail',
      meta: {
          title: '区域详情'
      },
      component: placeDetail
    },
    {
      path: '/boxDetail/:id',
      name: 'boxDetail',
      meta: {
          title: '区域管理'
      },
      component: boxDetail
    },
    {
      path: '/doorDetail/:id',
      name: 'doorDetail',
      meta: {
          title: '柜门管理'
      },
      component: doorDetail
    }
  ]
})

// router.beforeEach((to, from, next) => {
//   console.log(to)
//   if(to.meta.title){
//     window.document.title = to.meta.title
//   }
//   next()
// })

export default router