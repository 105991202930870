import { Toast } from 'vant'
import axios from 'axios'
import store from '../store/index.js'

// 不需要更新token的请求
const notUpdateToken = [
  '/sso/jwt/login'
]

// 默认超时180s
axios.defaults.timeout = 180000
// axios.defaults.timeout = 500
// 默认遇到错误重复请求2次
axios.defaults.retry = 0
axios.defaults.retryDelay = 1000
axios.defaults.handleTimeout = null
axios.defaults.withCredentials = true
// 附加token
axios.interceptors.request.use(async config => {
  // 排除不用附加token的路由
  if (notUpdateToken.includes(config.url)) return config
  // if (config.url.indexOf('10.64.200.70/ypxt/ypgj/sfz/') > -1) return config
  // 正常使用的系统中 token从redux中取到
  const token = store.state.token
  if (token) {
    config.headers.authentication = token
    return config
  }
  const stoken = sessionStorage.getItem('token')
  if (!stoken) {
    // 未获取到
    Toast({
      icon: 'cross',
      forbidClick: true,
      message: '丢失登录token，请重新登录或刷新浏览器重试，如若失败请联系管理员'
    })
    setTimeout(() => {
      window.location.href = '/login'
    }, 1500)
    return null
  }
  config.headers.authentication = stoken
  return config
}, error => Promise.reject(error))
// retry axios
const handleEorrorStatus = res => {
  const { config } = res
  if (res.status !== 200) {
    Toast({
      icon: 'cross',
      forbidClick: true,
      message: `${res.error}，错误代码：${res.status}`
    })
    return Promise.reject(res.exception)
  }
  if (res && res.data && res.data.statusCode && res.data.statusCode !== '201') {
    if (res.data.code && res.data.code === 200) return res
    if (res.data.resultCode && res.data.resultCode === '201') return res
    Toast({
      icon: 'cross',
      forbidClick: true,
      message: `${res.data.message || res.data.resultMsg || '未知'}，错误代码：${res.data.statusCode || res.data.resultCode || '未知'}`
    })
    if (config.handleError && typeof config.handleError === 'function') {
      config.handleError()
    }
    if (res.data && (res.data.statusCode === '401' || res.data.statusCode === '403' || res.data.statusCode === 'DEF001')) {
      Toast({
        icon: 'cross',
        forbidClick: true,
        message: `${res.error}，错误代码：${res.status}`
      })
      setTimeout(async () => {
        await sessionStorage.removeItem('token')
        window.location.href = '/login'
      }, 1500)
      return
    }
  }
  return res
}
axios.interceptors.response.use(handleEorrorStatus, err => {
  const config = err.config
  if (!config || !config.retry){
    Toast({
      icon: 'cross',
      forbidClick: true,
      message: `请求路径${err.config.url || '未知'}，${err.response && err.response.data && err.response.data.message ? err.response.data.message : '服务器错误'},错误代码：${err.response ? (err.response.status || '未知') : '未知'}`
    })
    return Promise.reject(err)
  }
  config.__retryCount = config.__retryCount || 0
  if (config.__retryCount >= config.retry) {
    // 处理最终错误
    
    if (err.code === 'ECONNABORTED') {
      Toast({
        icon: 'cross',
        forbidClick: true,
        message: `请求路径${err.config.url}超时`
      })
    } else {
      Toast({
        icon: 'cross',
        forbidClick: true,
        message: `请求路径${err.config.url || '未知'}，${err.response && err.response.data && err.response.data.message ? err.response.data.message : '服务器错误'},错误代码：${err.response ? (err.response.status || '未知') : '未知'}`
      })
    }
    return Promise.reject(err)
  }
  config.__retryCount += 1
  const backoff = new Promise((resolve => {
    setTimeout(() => {
      resolve()
    }, config.retryDelay || 1)
  })).catch(() => { })
  return backoff.then(() => axios(config))
})
