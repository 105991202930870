<template>
  <div id="app" :style="{ height: active < 4 && isDev ? 'calc(100vh - 1.33333rem)' : '100vh' }">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" v-wechat-title="$route.meta.title"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" v-wechat-title="$route.meta.title"></router-view>
    <Tabbar v-model="active" v-show="active < 3 && isDev">
      <TabbarItem :class="{active: active === 0}" @click="$router.push('/bagIn')">
        <router-link to="/bagIn">
          <div :class="{activeTab: active === 0}">存包</div>
        </router-link>
      </TabbarItem>
      <TabbarItem :class="{active: active === 1}" @click="$router.push('/bagOut')">
        <router-link to="/bagOut">
          <div :class="{activeTab: active === 1}">取包</div>
        </router-link>
      </TabbarItem>
      <TabbarItem :class="{active: active === 2}" @click="$router.push('/minePage')">
        <router-link to="/minePage">
          <div :class="{activeTab: active === 2}">我的</div>
        </router-link>
      </TabbarItem>
    </Tabbar>
    <Overlay :show="show" @click="show = false" class="overlay">
      <Loading type="spinner" />
    </Overlay>
  </div>
</template>

<script>
import { Tabbar, TabbarItem, Overlay, Loading } from 'vant'
import axios from 'axios'
export default {
  name: 'App',
  data(){
    return {
      show: false,
      active: 0,
      isDev: process.env.NODE_ENV === 'development'
    }
  },
  components: {
    Tabbar, TabbarItem, Overlay, Loading
  },
  mounted(){
    let path = this.$route.path
    this.active = path === '/bagIn' ? 0 : path === '/bagOut' ? 1 : path === '/minePage' ? 2 : 4
    const userVo = sessionStorage.getItem('userVo')
    if (userVo || this.$store.state.systemType === 'manager') return
    this.show = true
    axios.post('/user/info').then(res => {
      if (res && res.data && res.data.statusCode === '201') {
        this.$store.dispatch('getUserInfo2', res.data.result)
        sessionStorage.setItem('userVo', JSON.stringify(res.data.result))
        this.show = false
        // this.$router.push('/bagIn')
      } else {
        this.show = false
      }
    })
    this.getData()
  },
  methods: {
    getData(){
      console.log(process.env.NODE_ENV)
    }
  },
  watch:{
    $route(to){
      this.active = to.path === '/bagIn' ? 0 : to.path === '/bagOut' ? 1 : to.path === '/minePage' ? 2 : 4
    }
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  margin-top: 0px;
  height: calc(100vh - 11.33333rem);
  display: flex;
  background: rgb(242 243 245 / 59%);
  padding: 15px;
  box-sizing: border-box;
}
#app a{
  display: block;
  color: black;
}
.active{
  background: #07c160;
  color: #fff!important;
}
.activeTab{
  color: #fff!important;
}
.van-tabbar-item{
  display: flex;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
  color: transparent;
}
.overlay{
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
